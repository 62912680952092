// this array should match the array at server/api/etl/converter/pdfReportCard/index.js
const allowedFileTypes = [{
    name: 'reportCardsHS-NYC',
    extension: 'pdf',
    findValues: [
        { index: 0, value: 'Report Card for ' },
    ],
}, {
    name: 'reportCardsElem-NYC',
    extension: 'pdf',
    findValues: [
        { index: 0, value: 'Report Card ' },
    ],
}, {
    name: 'reportCardsMiddleSchool-NYC',
    extension: 'pdf',
    findValues: [
        { index: 1, value: 'Student Report Card' },
    ],
}, {
    name: 'schedules-NYC',
    extension: 'pdf',
    findValues: [
        { index: 9, value: 'Student Program Card' },
        { index: 17, value: 'Student Program Card' },
    ],
}, {
    name: 'gridSchedules-NYC',
    extension: 'pdf',
    findValues: [
        { index: 2, value: 'Grid Schedule' },
        { index: 26, value: 'Grid Schedule' },
    ],
}, {
    name: 'transcripts-NYC',
    extension: 'pdf',
    findValues: [
        { index: 3, value: 'Student Transcript ' },
        { index: 1, value: 'Student Transcript' },
        { index: 3, value: 'Student Permanent Record' },
    ],
}];

export default function identify(file, user, callback) {
    const reader = new FileReader();

    reader.onloadend = function (event) {
        const data = event.target.result;
        // pdfjs is loaded in the mounted() event of the FileUploader
        const pdfjs = window.pdfjsLib;
        pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

        const loadingTask = pdfjs.getDocument(data);
        loadingTask.promise.then((pdf) => {
            pdf.getPage(1).then((page) => {
                page.getTextContent().then((pdfContent) => {
                    if (pdfContent.items.length == 0) return callback('invalid pdf');
                    // window.console.log(pdfContent.items);

                    const match = allowedFileTypes.find((fileType) => fileType.findValues.some((toFind) => pdfContent.items[toFind.index].str.startsWith(toFind.value)));

                    if (!match) return callback('Unknown file type');
                    callback(null, match.name);
                });
            });
        });
    };

    reader.onerror = function () {
        return callback('Error reading file');
    };
    reader.readAsArrayBuffer(file);
}
