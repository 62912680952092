<template>
<div
    class="kt-portlet kt-portlet--fit kt-portlet--head-lg"
    :class="{
        'kt-portlet--skin-solid kt-portlet--head-overlay': backgroundColor !== '',
    }"
>
    <div
        class="kt-portlet__head kt-portlet__space-x"
        :class="{'kt-portlet__head--noborder': backgroundColor !== ''}"
    >
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{ portletTitle }}
                <small v-if="portletSubTitle">
                    {{ portletSubTitle }}
                </small>
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <span
                v-if="false"
                class="kt-badge kt-badge--inline kt-badge--unified-brand kt-badge--lg kt-badge--rounded kt-badge--bold"
            >
                {{ systemState }}
            </span>
        </div>
    </div>
    <div class="kt-portlet__body kt-portlet__body--fit">
        <div class="kt-widget17">
            <div
                v-if="backgroundColor !== ''"
                class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides"
                :style="backgroundColor"
            >
                <div class="kt-widget17__chart">
                    <div class="p-3" />
                </div>
            </div>
            <div
                class="kt-widget17__stats justify-content-center"
                :class="{'mt-3 pt-1': backgroundColor == ''}"
            >
                <div
                    v-for="(file, idx) in formattedFiles"
                    :key="`syncFileId_${idx}_${file.syncFileId}`"
                    class="kt-widget17__items"
                >
                    <a
                        :href="file.downloadUrl || '#'"
                        :target="file.downloadUrl ? '_blank' : '_self'"
                        class="kt-widget17__item pl-3 pr-3 pt-4 pb-4"
                    >
                        <span
                            v-if=" file.status == 'ERROR'"
                            class="kt-widget17__icon"
                        >
                            <SVGIcon
                                :hex-color="'#fd397a'"
                                name="file-error"
                            />
                        </span>
                        <span v-else-if="file.status == 'UPLOADING'">
                            <h4 class="kt-font-success pt-3">{{ file.percent }}%</h4>
                        </span>
                        <span
                            v-else
                            class="kt-widget17__icon"
                            :style="file.status == 'MISSING' || file.status == 'OLD' ? 'opacity: 0.4' : ''"
                        >
                            <SVGIcon :name="file.icon" />
                        </span>

                        <span class="kt-widget17__subtitle">
                            <span
                                class="kt-font-danger"
                                title="required"
                            >
                                *
                            </span>
                            {{ file.name }}
                        </span>
                        <span class="kt-widget17__desc">
                            {{ file.caption }}
                        </span>
                    </a>
                </div>
            </div>
            <div class="py-3 px-5">
                <div
                    v-if="someMissing"
                    class="alert alert-outline-dark fade show"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="flaticon-warning kt-font-danger" />
                    </div>
                    <div class="alert-text">
                        All {{ files.length }} STARS files must be uploaded at once to enable processing.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import dataSystemMixins from '../../store/mixins/dataSystemMixins';

export default Vue.extend({
    name: 'DataSystemStarsClassroom',
    mixins: [dataSystemMixins],
    props: {
        processing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            name: 'STARS Classroom',
            extension: 'xlsx',
            debounce: null,
            key: 0,
            uploadedFiles: [],
            states: {
                STAGNANT: 'STAGNANT', // uploaded, processed, but old
                PARTIAL: 'PARTIAL', // missing any
                READY: 'READY',
                PROCESSING: 'PROCESSING',
                COMPLETE: 'COMPLETE', // done
                ERROR: 'ERROR',
            },
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        dataSystemStarsClassroom() {
            return this.$_dataSystemMixins_getDataSystemForStarsClassroom;
        },
        dataSystem() {
            return this.dataSystemStarsClassroom;
        },
        dataSystemId() {
            return this.dataSystem.dataSystemId;
        },
        someMissing() {
            return this.files.some((f) => Boolean(f.uploadedFile && f.uploadedFile.isImported === false));
        },
        files() {
            const { extension } = this;
            const now = moment();
            return this.dataSystem.files
                .filter((f) => f.syncFile.fileExtension == extension || extension == '*')
                .map((f) => {
                    const file = { ...f };
                    file.daysOld = -1;
                    if (file?.uploadedFile?.uploadDate) {
                        const fileDate = moment.utc(f.uploadedFile.uploadDate).local(true);
                        const daysOld = moment.duration(fileDate.diff(now)).asDays();
                        file.daysOld = daysOld;
                    }
                    return file;
                });
        },
        formattedFiles() {
            const { schoolId, schoolTermId } = this.user.school;
            return this.files.map((f) => {
                const { syncFile, localFile, uploadedFile } = f;
                const { syncFileId } = syncFile;

                const output = {
                    syncFileId,
                    status: 'MISSING',
                    icon: syncFile.fileExtension,
                    percent: 0,
                    name: syncFile.externalName.replace('Custom Report ', ''),
                    caption: 'Missing',
                    downloadUrl: uploadedFile ? `/api/schools/${schoolId}/terms/${schoolTermId}/syncFiles/${syncFileId}/download` : null,
                };

                if (localFile) {
                    output.status = 'PENDING';
                    output.caption = 'Pending';
                    return output;
                }

                if (uploadedFile) {
                    if (!uploadedFile.isImported) {
                        output.status = 'PENDING';
                        output.caption = 'Pending';
                    } else {
                        output.status = 'RECENT';
                        output.caption = `${moment.utc(uploadedFile.uploadDate).local(true).fromNow(true)} old`;
                        if (moment(uploadedFile.uploadDate).isBefore(moment().add(-5, 'days'))) {
                            output.status = 'OLD';
                        }
                    }
                    return output;
                }

                return output;
            });
        },
        systemState() {
            const { states, files } = this;
            const hasError = files.some((f) => Boolean(f.uploadedFile && f.uploadedFile.importError));

            if (this.processing) return states.PROCESSING;

            const someMissing = files.some((f) => Boolean(!f.uploadedFile));
            if (someMissing) return states.PARTIAL;

            // const someReadyToProcess = files.some((f) => Boolean(f.uploadedFile && f.uploadedFile.isImported === false));
            // const allReadyToProcess = files.every((f) => Boolean(f.uploadedFile && f.uploadedFile.isImported === false));

            if (hasError) return states.ERROR;

            // if (allReadyToProcess) return states.PROCESSING;
            // if (someReadyToProcess) return states.PROCESSING;

            // nothing processing, so over state is based on age
            // const someRecentlyProcessed = this.files.some((f) => f.daysOld < 2);
            const allRecentlyProcessed = this.files.every((f) => f.daysOld < 2);

            if (allRecentlyProcessed) return states.COMPLETE;
            // if (!isGroupedProcessing && someRecentlyProcessed) return states.COMPLETE;

            return states.STAGNANT;
        },
        backgroundColor() {
            const { systemState } = this;
            if (systemState == 'ERROR') {
                return 'background-color: #fd397a';
            }
            if (systemState == 'READY' || systemState == 'COMPLETE') {
                return '';// 'background-color: rgb(0 123 255 / 80%)';
            }
            if (systemState == 'STAGNANT') {
                return 'background-color: #ffc107;';
            }
            if (systemState == 'PARTIAL' || systemState == 'PROCESSING') {
                return 'background-color: #fbaa00'; // yellow
            }
            return '';
        },
        portletSubTitle() {
            const { extension, processing } = this;
            if (extension !== '*' && !processing) {
                return extension.toUpperCase();
            }
            return null;
        },
        portletTitle() {
            const { systemState, states } = this;
            const { dataSystem } = this;

            if (systemState == states.PROCESSING) return 'Processing Files, please wait..';
            // if (systemState == states.PARTIAL) return 'Additional uploads are needed to process.';
            if (systemState == states.ERROR) return `${dataSystem.dataSystemName} Upload Error`;
            return `${dataSystem.dataSystemName}`;
        },
    },
    methods: {

    },
});

</script>

<style scoped>
.kt-widget17 .kt-widget17__stats {
    flex-wrap: wrap;
    display: flex;
    margin: -4.3rem auto 0 auto;
    position: relative;
    width: 95%;
}

.kt-widget17__items {
    float: left;
}

.kt-widget17__item {
    text-align: center;
    min-width: 140px;
    max-width: 300px;
    width: 300px;
}

.kt-widget17__subtitle,
.kt-widget17__desc {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    display: block;
}
</style>
