import XLSX from '@sheet/crypto/dist/xlsx.full.min';

const allowedFileTypes = [{
    name: 'classroom_schedules',
    match: 'Teacher Subject Class Report',
    cellLocation: ['D3'],
    extension: '.xlsx',
}, {
    name: 'classroom_rosters',
    match: 'Teacher/Staff Details',
    cellLocation: ['A3', 'A5'], // Two versions of this file have been seen
    extension: '.xlsx',
}];

export default function identify(file, user, callback) {
    const reader = new FileReader();
    reader.onload = function (event) {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        if (workbook.Sheets.length === 0) return callback('Parse error');
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const match = allowedFileTypes.find((fileType) => {
            let foundMatch = false;
            fileType.cellLocation.forEach((cellLocation) => {
                const cell = worksheet[cellLocation];
                let cellValue = (cell ? cell.v : null);
                cellValue = (cellValue || '').trim();
                if (cellValue == fileType.match) foundMatch = true;
            });
            return foundMatch;
        });

        if (!match) return callback('Unsupported file');
        return callback(null, match.name);
    };
    reader.onerror = function () {
        return callback('Error reading file');
    };
    reader.readAsArrayBuffer(file);
}
