<template>
<div
    class="kt-portlet kt-portlet--fit kt-portlet--head-lg"
    :class="{
        'kt-portlet--skin-solid kt-portlet--head-overlay': backgroundColor !== '',
    }"
>
    <div
        class="kt-portlet__head kt-portlet__space-x"
        :class="{'kt-portlet__head--noborder': backgroundColor !== ''}"
    >
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{ portletTitle }}
                <small v-if="portletSubTitle">
                    {{ portletSubTitle }}
                </small>
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <span
                v-if="false"
                class="kt-badge kt-badge--inline kt-badge--unified-brand kt-badge--lg kt-badge--rounded kt-badge--bold"
            >
                {{ systemState }}
            </span>
        </div>
    </div>
    <div class="kt-portlet__body kt-portlet__body--fit">
        <div
            class="kt-widget17"
        >
            <div
                v-if="backgroundColor !== ''"
                class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides"
                :style="backgroundColor"
            >
                <div class="kt-widget17__chart">
                    <div class="p-3" />
                </div>
            </div>
            <div
                class="kt-widget17__stats justify-content-center"
                :class="{'mt-3 pt-1': backgroundColor == ''}"
            >
                <div
                    v-for="file in formattedFiles"
                    :key="`syncFileId_${file.syncFileId}`"
                    class="kt-widget17__items"
                >
                    <a
                        :href="file.downloadUrl || '#'"
                        :target="file.downloadUrl ? '_blank' : '_self'"
                        class="kt-widget17__item pl-3 pr-3 pt-4 pb-4"
                    >
                        <span
                            v-if="file.status == 'ERROR'"
                            class="kt-widget17__icon"
                        >
                            <SVGIcon
                                :hex-color="'#fd397a'"
                                name="file-error"
                            />
                        </span>
                        <span v-else-if="file.status == 'UPLOADING'">
                            <h4 class="kt-font-success pt-3">{{ file.percent }}%</h4>
                        </span>
                        <span
                            v-else
                            class="kt-widget17__icon"
                            :style="file.status == 'MISSING' || file.status == 'OLD' ? 'opacity: 0.4' : ''"
                        >
                            <SVGIcon :name="file.icon" />
                        </span>

                        <span class="kt-widget17__subtitle">
                            {{ file.name }}
                        </span>
                        <span class="kt-widget17__desc">
                            {{ file.caption }}
                        </span>
                    </a>
                </div>
            </div>
            <div class="p-3" />
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import async from 'async';
import dataSystemMixins from '../../store/mixins/dataSystemMixins';
import * as network from '../../network';
import Types from '../../store/Types';
import { decryptSchoolTermEncryptionKey } from '../../lib/cipher';

export default Vue.extend({
    name: 'DataSystemGoogle',
    mixins: [dataSystemMixins],
    props: {
        extension: {
            type: String,
            required: false,
            default: '*',
        },
    },
    data() {
        return {
            name: 'Google Linking',
            key: 0,
            processing: false,
            states: {
                STAGNANT: 'STAGNANT', // uploaded, processed, but old
                PARTIAL: 'PARTIAL', // missing any
                READY: 'READY',
                PROCESSING: 'PROCESSING',
                DISABLED: 'DISABLED',
                COMPLETE: 'COMPLETE', // done
                ERROR: 'ERROR',
            },
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        dataSystemGoogle() {
            return this.$_dataSystemMixins_getDataSystemForGoogle;
        },
        dataSystem() {
            return this.dataSystemGoogle;
        },
        dataSystemId() {
            return this.dataSystem.dataSystemId;
        },
        importableFiles() {
            const now = moment();
            const files = [];

            this.dataSystem.files.forEach((f) => {
                const file = f;
                file.daysOld = -1;
                if (file?.uploadedFile?.uploadDate) {
                    const fileDate = moment.utc(f.uploadedFile.uploadDate).local(true);
                    const daysOld = moment.duration(fileDate.diff(now)).asDays();
                    file.daysOld = daysOld;
                }

                if (file.syncFile.fileExtension == 'csv') {
                    files.push(file);
                }
            });

            return files.sort((a, b) => {
                if (a.importRank < b.importRank) return -1;
                if (a.importRank > b.importRank) return 1;
                return 0;
            });
        },
        files() {
            const { extension } = this;
            const now = moment();
            return this.dataSystem.files
                .filter((f) => f.syncFile.fileExtension == extension || extension == '*')
                .map((f) => {
                    const file = f;
                    file.daysOld = -1;
                    if (file?.uploadedFile?.uploadDate) {
                        const fileDate = moment.utc(f.uploadedFile.uploadDate).local(true);
                        const daysOld = moment.duration(fileDate.diff(now)).asDays();
                        file.daysOld = daysOld;
                    }
                    return file;
                });
        },
        formattedFiles() {
            const { schoolId, schoolTermId } = this.user.school;
            return this.files.map((f) => {
                const { syncFile, localFile, uploadedFile } = f;
                const { syncFileId } = syncFile;

                const output = {
                    syncFileId,
                    status: 'MISSING',
                    icon: syncFile.fileExtension,
                    percent: 0,
                    name: syncFile.externalName,
                    caption: 'Missing',
                    downloadUrl: uploadedFile ? `/api/schools/${schoolId}/terms/${schoolTermId}/syncFiles/${syncFileId}/download` : null,
                };

                if (localFile && localFile?.filePresentation) {
                    const { filePresentation } = localFile;

                    output.status = 'UPLOADING';
                    output.caption = `${filePresentation.status} ${filePresentation.stringPercentage}`;
                    output.percent = filePresentation.percentComplete;
                    if (filePresentation.status == 'Uploaded') {
                        output.status = 'UPLOADED';
                        output.caption = 'Uploaded';
                    }

                    if (filePresentation.error) {
                        output.caption = filePresentation.error;
                        output.status = 'ERROR';
                    }
                    return output;
                }

                if (uploadedFile) {
                    output.status = 'RECENT';
                    output.caption = `${moment.utc(uploadedFile.uploadDate).local(true).fromNow(true)} old`;
                    if (!uploadedFile.isImported || moment(uploadedFile.uploadDate).isBefore(moment().add(-7, 'days'))) {
                        output.status = 'OLD';
                    }
                    return output;
                }

                return output;
            });
        },
        systemState() {
            const { states, files } = this;

            // if (this.disabled) return states.DISABLED;
            if (this.processing) return states.PROCESSING;

            // const someMissing = files.some((f) => f.uploadedFile && f.uploadedFile.isImported === false);
            // if (someMissing) return states.PARTIAL;

            // const someProcessing = files.some((f) => Boolean(f.uploadedFile && f.uploadedFile.isImported === false));
            // if (someProcessing) return states.PROCESSING;

            const hasError = files.some((f) => Boolean(f.uploadedFile && f.uploadedFile.importError === true));
            if (hasError) return states.ERROR;

            // nothing processing, so over state is based on age
            const someOld = this.files.some((f) => f.daysOld > 7);

            if (someOld) return states.STAGNANT;

            return states.COMPLETE;
        },
        backgroundColor() {
            const { systemState } = this;
            if (systemState == 'ERROR' || systemState == 'DISABLED') {
                return 'background-color: #fd397a';
            }
            if (systemState == 'READY' || systemState == 'COMPLETE') {
                return '';// 'background-color: rgb(0 123 255 / 80%)';
            }
            if (systemState == 'STAGNANT') {
                return 'background-color: #ffc107;';
            }
            if (systemState == 'PARTIAL' || systemState == 'PROCESSING') {
                return 'background-color: #fbaa00'; // yellow
            }
            return '';
        },
        portletSubTitle() {
            const { extension, processing } = this;
            if (extension !== '*' && !processing) {
                return extension.toUpperCase();
            }
            return null;
        },
        portletTitle() {
            const { systemState, states } = this;
            const { dataSystem } = this;

            if (systemState == states.PROCESSING) return 'Processing Files, please wait..';
            if (systemState == states.PARTIAL) return 'Additional uploads are needed to process.';
            if (systemState == states.ERROR) return 'Upload Error';
            return `${dataSystem.dataSystemName}`;
        },
    },
    methods: {
        startProcessing(callback) {
            const { user, $store, importableFiles } = this;

            if (this.processing) return;
            const changedFiles = this.importableFiles
                .filter((f) => f.uploadedFile && f.uploadedFile.isUploaded && f.uploadedFile.isImported === false)
                .sort((a, b) => ((a.syncFile.importRank > b.syncFile.importRank) ? 1 : -1));
            if (changedFiles.length == 0) {
                window.console.log('No google files changed');
                return callback();
            }
            this.processing = true;
            window.console.log(`Starting to process ${changedFiles.length} ${this.name}`);

            const { schoolId, schoolTermId } = user.school;
            const toPostProcess = [];
            const v = this;
            // re-process any files changed
            const stek = window.sessionStorage.getItem(`stek_${schoolTermId}`);

            async.auto({
                schoolTermEncryptionKey(next) {
                    if (!stek) return next(null, null);
                    decryptSchoolTermEncryptionKey(user.school, next);
                },
                upload: ['schoolTermEncryptionKey', (results, next) => {
                    const { schoolTermEncryptionKey } = results;
                    async.eachOfLimit(changedFiles, 1, (file, idx, nextFile) => {
                        const { syncFileId, internalName } = file.syncFile;
                        const params = {
                            url: { schoolId, schoolTermId, syncFileId },
                            body: { schoolTermEncryptionKey },
                        };
                        network.storage.processUpload(params, (err, res) => {
                            if (err) return nextFile(err);
                            // delay the processing of items that couldnt be imported because
                            // they have dependancies that have to be imported first
                            const { pendingProcessing } = res;
                            if (pendingProcessing.length == 0) {
                                window.console.log(`${internalName} was successfully processed and returned no dependancies`);
                                return nextFile(err);
                            }
                            window.console.log(`${internalName} was successfully processed with delayed dependancies: ${pendingProcessing.map((p) => p.internalName).join(', ')}`);
                            // remember unique dependancy list
                            pendingProcessing.forEach((p) => {
                                const exists = toPostProcess.find((d) => d.syncFileId == p.syncFileId);
                                if (!exists) {
                                    const canImport = importableFiles.find((d) => d.syncFileId == p.syncFileId) && allowedFiles.find((d) => d.syncFile.syncFileId == p.syncFileId);
                                    if (canImport) toPostProcess.push(p);
                                }
                            });
                            if (toPostProcess.length == 0) {
                                window.console.log(`${internalName} was successfully processed with delayed dependancies: ${toPostProcess.map((p) => p.internalName).join(', ')}`);
                            }
                            nextFile(err);
                        });
                    }, next);
                }],
            }, 5, (err, results) => {
                if (err) return callback(err);
                const { schoolTermEncryptionKey } = results;
                $store.dispatch(Types.actions.REFRESH_DATA_SYSTEMS, (err2) => {
                    if (err2) return callback(err2);
                    const pendingProcessing = toPostProcess
                        .filter((p) => {
                            const alreadyProcessed = changedFiles.find((file) => {
                                const { uploadedFile, syncFileId, isImported } = file;
                                if (!uploadedFile) return false;
                                return syncFileId == p.syncFileId && isImported;
                            });
                            return !alreadyProcessed;
                        })
                        .sort((a, b) => {
                            if (a.importRank < b.importRank) return -1;
                            if (a.importRank > b.importRank) return 1;
                            return 0;
                        });

                    window.console.log(`Processing delayed dependancies: ${pendingProcessing.map((p) => p.internalName).join(', ')}`);
                    async.eachSeries(pendingProcessing, (file, nextFile) => {
                        $store.commit(Types.mutations.CLEAR_UPLOADED_FILES_BY_NAME, file.internalName);
                        const { syncFileId } = file;
                        const params = {
                            url: { schoolId, schoolTermId, syncFileId },
                            body: { schoolTermEncryptionKey },
                        };
                        network.storage.processUpload(params, (err3) => {
                            if (err3) {
                                window.console.error(`Deferred processing failed for ${file.internalName}`);
                                return nextFile(err3);
                            }
                            window.console.log(`Deferred processing for ${file.internalName} succeeded`);
                            nextFile(err3);
                        });
                    }, (err4) => {
                        setTimeout(() => {
                            v.processing = false;
                        }, 1000);

                        if (err4) return callback(err4);
                        callback(err4);
                    });
                });
            });
        },
    },
});

</script>

<style scoped>
.kt-widget17 .kt-widget17__stats {
    flex-wrap: wrap;
    display: flex;
    margin: -4.3rem auto 0 auto;
    position: relative;
    width: 95%;
}

.kt-widget17__items {
    float: left;
}

.kt-widget17__item {
    text-align: center;
    min-width: 140px;
    max-width: 140px;
    width: 140px;
}

.kt-widget17__subtitle,
.kt-widget17__desc {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    display: block;
}
</style>
