<template>
<div v-if="serverSideEncryptionEnabled && clientSideEncryptionEnabled">
    <div class="row mr-0 ml-0 w-100">
        <div
            class="alert alert-light alert-elevate w-100 fade show"
            role="alert"
        >
            <div class="alert-icon">
                <i class="la la-info-circle kt-font-danger" />
            </div>
            <div class="alert-text">
                Encryption is enabled for this school term.
                In order to upload updated rosters, you must first unlock the encryption.
            </div>
        </div>
    </div>
    <div class="row mr-0 ml-0 w-100 text-center">
        <button
            type="button"
            style="min-width: 260px; margin: auto;"
            class="btn btn-label-primary btn-lg kt-font-lg btn-upper mt-3"
            @click="startDecryption"
        >
            Unlock
        </button>
    </div>
</div>
<div
    v-else
    class="kt-container kt-grid__item kt-grid__item--fluid"
    @dragleave="onDragLeave"
    @dragover="onDragOver"
    @drop="onDrop"
>
    <div
        v-if="showDropZone"
        class="w-100"
    >
        <a class="kt-header__brand-logo w-100 text-center">
            <SVGIcon
                name="dropZone"
                class="logo loading"
            />

            <h1 class="kt-font-bolder">
                Drop files here to upload!
            </h1>
        </a>
    </div>

    <template v-else>
        <SetupWizardStepStatus
            v-if="isSetupWizard && step"
            :status="step.status"
            :message="step.message"
        />

        <div class="row">
            <div class="col">
                <div
                    class="alert alert-light alert-elevate fade show"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="flaticon-warning kt-font-brand" />
                    </div>
                    <div class="alert-text">
                        Place all of the STARS and ATS downloads in one folder,
                        then drag and drop or select them all to process uploads.
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="!processing"
            class="row"
        >
            <div :class="[ errorFiles.length == 0 ? 'col-12' : 'col-xl-6 col-lg-6 col-md-6']">
                <div class="kt-portlet kt-portlet--height-fluid">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                File Uploader
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <!-- bar -->
                        </div>
                    </div>

                    <div class="kt-portlet__body">
                        <input
                            id="file-input"
                            type="file"
                            name="file"
                            style="display: none"
                            accept=".csv"
                            multiple
                            @change="onFileBrowseSelect"
                        >

                        <div class="form-group pt-4">
                            <div
                                class="input-group"
                                style="cursor: pointer"
                                @click.stop.prevent="triggerFileBrowser"
                            >
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Choose Files"
                                    readonly="readonly"
                                >
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        Browse
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="errorFiles.length !== 0"
                class="col-xl-6 col-lg-6 col-md-6"
            >
                <ErrorBin :error-files="errorFiles" />
            </div>
        </div>
        <div
            v-if="successMessage && !processing"
            class="row"
        >
            <div class="col-12">
                <div class="kt-demo__preview">
                    <div
                        class="alert alert-success"
                        role="alert"
                    >
                        <div class="alert-text">
                            <h4 class="alert-heading">
                                {{ successMessage }}!
                            </h4>
                            <p class="mt-3">
                                You have successfully imported new roster data into SyncGrades. Reload this page to be sure you are seeing the most recent rosters
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="district === 'ICAHN'"
            class="row"
        >
            <div class="col">
                <div
                    class="alert alert-light alert-elevate fade show"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="la la-info-circle kt-font-danger" />
                    </div>
                    <div class="alert-text pt-2 pb-2">
                        An updated ROCL is always required to be uploaded and processed FIRST before any other files.
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div :class="[(starsAdmin && starsClassroom) || !rostersReady ? 'col-12' : 'col-xl-6 col-lg-6 col-md-6']">
                <DataSystemStarsAdmin
                    v-if="starsAdmin"
                    ref="dataSystemStarsAdmin"
                    name="STARS Admin"
                    extension="csv"
                />
                <DataSystemStarsClassroom
                    v-if="starsClassroom"
                    ref="dataSystemStarsClassroom"
                    name="STARS Classroom"
                    extension="xlsx"
                    :processing="STARSClassroomProcessing"
                />
            </div>
            <div
                v-if="rostersReady"
                :class="[!starsClassroom && !starsAdmin ? 'col-12' : 'col-xl-6 col-lg-6 col-md-6']"
            >
                <DataSystemAts
                    v-if="ats"
                    ref="dataSystemAts"
                    name="ATS"
                />

                <DataSystemGoogle
                    v-if="!$_userMixins_isGoogleDisabled"
                    ref="dataSystemGoogle"
                    name="Google Linking"
                />
            </div>
        </div>
    </template>
</div>
</template>

<script>

import async from 'async';
import moment from 'moment';
import Vue from 'vue';
import Types from '../store/Types';
import DataSystemStarsAdmin from '../components/uploads/DataSystemStarsAdmin.vue';
import DataSystemStarsClassroom from '../components/uploads/DataSystemStarsClassroom.vue';
import DataSystemAts from '../components/uploads/DataSystemAts.vue';
import DataSystemGoogle from '../components/uploads/DataSystemGoogle.vue';
import ErrorBin from '../components/uploads/ErrorBin.vue';
import userMixins from '../store/mixins/userMixins';

import * as util from '../lib/uploads/util';
import identify from '../lib/uploads/identify';
import dataSystemMixins, { getDataSystemByName } from '../store/mixins/dataSystemMixins';
import * as network from '../network';
import SetupWizardStepStatus from '../components/SetupWizardStepStatus.vue';

import * as STARSClassroomUtil from '../lib/uploads/STARSClassroom';

export default Vue.extend({
    name: 'UploadRosters',
    components: {
        DataSystemStarsAdmin,
        DataSystemStarsClassroom,
        DataSystemAts,
        DataSystemGoogle,
        ErrorBin,
        SetupWizardStepStatus,
    },
    mixins: [userMixins, dataSystemMixins],
    data() {
        return {
            dragTimer: null,
            loading: false,
            showDropZone: false,
            processing: false,
            STARSClassroomProcessing: false,
            successMessage: null,
            files: [],
            errorFiles: [],
        };
    },
    computed: {
        isSetupWizard() {
            return this.$route.name == 'SetupWizardRoute';
        },
        step() {
            return this.$store.state.wizard.steps.find((step) => step.routeName === 'SetupDataUploads');
        },
        user() {
            return this.$store.state.user;
        },
        clientSideEncryptionEnabled() {
            return this.user.school.clientSideEncryptionEnabled;
        },
        serverSideEncryptionEnabled() {
            return this.user.school.serverSideEncryptionEnabled;
        },
        encryptionHash() {
            return this.user.school.encryptionHash;
        },
        district() {
            const { user } = this;
            return user.school.externalDistrictId;
        },
        starsClassroom() {
            const { dataSystems } = this.$store.state.database;
            const stars = getDataSystemByName(dataSystems, 'STARS Classroom');
            if (!stars) return null;
            stars.files = stars.files.slice();
            return stars;
        },
        starsAdmin() {
            const { dataSystems } = this.$store.state.database;
            const stars = getDataSystemByName(dataSystems, 'STARS Admin');
            if (!stars) return null;
            stars.files = stars.files.filter((f) => (stars.dataSystem == 'STARS Admin' && f.syncFile.fileExtension == 'csv'));
            if (!stars.files.length) return null;
            return stars;
        },
        ats() {
            const { dataSystems } = this.$store.state.database;
            const ats = getDataSystemByName(dataSystems, 'ATS');
            if (!ats) return null;
            ats.files = ats.files.filter((f) => (ats.dataSystem == 'ATS' && f.syncFile.fileExtension == 'csv'));
            return ats;
        },
        starsAdminProcessed() {
            const { district, starsAdmin } = this;
            if (['ICAHN'].includes(district)) return true;
            if (!starsAdmin) return false;
            return starsAdmin.files
                .filter((f) => f.syncFile?.isRequired)
                .every((f) => f.uploadedFile?.isImported);
        },
        starsClassroomProcessed() {
            if (!this.starsClassroom) return false;
            return this.starsClassroom.files
                .filter((f) => f.syncFile?.isRequired)
                .every((f) => f.uploadedFile?.isImported);
        },
        dataSystems() {
            const { starsAdmin, starsClassroom } = this;
            const { dataSystems } = this.$store.state.database;
            let output = [];
            if (starsAdmin) {
                output = [starsAdmin];
                const ready = (starsAdmin && starsAdmin.files.every((f) => Boolean(f.uploadedFile)));
                if (ready) {
                    const dataSystemStarsAts = getDataSystemByName(dataSystems, 'ATS');
                    const dataSystemStarsGoogle = getDataSystemByName(dataSystems, 'Google Linking');
                    output.push(dataSystemStarsAts);
                    output.push(dataSystemStarsGoogle);
                }
            }

            if (starsClassroom) {
                output = [starsClassroom];
                const ready = (starsClassroom && starsClassroom.files.every((f) => Boolean(f.uploadedFile)));
                if (ready) {
                    const dataSystemStarsAts = getDataSystemByName(dataSystems, 'ATS');
                    const dataSystemStarsGoogle = getDataSystemByName(dataSystems, 'Google Linking');
                    output.push(dataSystemStarsAts);
                    output.push(dataSystemStarsGoogle);
                }
            }
            return output;
        },
        rostersReady() {
            const { district, starsAdmin, starsClassroom } = this;
            if (['ICAHN'].includes(district)) return true;
            if (!starsClassroom && !starsAdmin) return false;
            const { starsAdminProcessed, starsClassroomProcessed } = this;
            return starsAdminProcessed || starsClassroomProcessed;
        },
        isDemoSchool() {
            return this.district == 'DEMO';
        },
    },
    watch: {
        starsAdminProcessed: {
            handler() {
                if (this.starsAdminProcessed && this.isSetupWizard) {
                    window.location.reload();
                }
            },
        },
    },
    mounted() {
        this.loadErrorFiles();
    },
    methods: {
        startDecryption() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        loadErrorFiles() {
            const v = this;
            const { schoolId, schoolTermId } = this.user.school;
            const params = {
                url: { schoolId, schoolTermId },
            };
            v.errorFiles = [];
            network.storage.getErrorBin(params, (err, res) => {
                if (err) return v.showError(err);
                v.errorFiles = res.files.map((f) => {
                    const file = f;
                    file.relativeDate = moment(f.fileUploaded).fromNow();
                    return file;
                });
            });
        },
        loadFiles(files) {
            this.hideDropZone();

            if (this.loading) return;
            this.loading = true;

            const { user } = this;
            const { dataSystems } = this.$store.state.database;
            const {
                showError, uploadFiles, loadErrorFiles, initiateClassroomProcessing,
            } = this;
            const v = this;
            const classroomDataSystem = dataSystems.find((ds) => ds.dataSystemName == 'STARS Classroom') || null;

            identify(files, dataSystems, user, (err, results) => {
                v.loading = false;
                if (err) showError(err);

                const hasUnidentified = results.some((f) => !f.syncFile);
                if (hasUnidentified) {
                    loadErrorFiles();
                }

                if (err) return;
                // only upload identified

                const STARSClassroomFiles = results.filter((f) => {
                    if (!f.syncFile) return false;
                    if (!classroomDataSystem) return false;
                    return f.syncFile.dataSystemId == classroomDataSystem.dataSystemId;
                });

                uploadFiles(results.filter((f) => {
                    if (!f.syncFile) return false;
                    if (classroomDataSystem) return f.syncFile.dataSystemId != classroomDataSystem.dataSystemId;
                    return true;
                }));

                if (STARSClassroomFiles.length) {
                    initiateClassroomProcessing();
                }
            });
        },
        uploadFiles(files) {
            const { user, showError } = this;
            const { loadErrorFiles, initiateProcessing } = this;
            const { showSuccess } = this;
            const v = this;
            if (!files.length) return;
            async.eachOfLimit(files, 1, (file, idx, next) => {
                util.uploadFile(file, user, (err) => {
                    if (err) {
                        showError(err);
                        return util.uploadError(file, user, next);
                    }
                    window.console.log(`Uploaded ${file.syncFile.internalName}`);
                    next();
                });
            }, (err) => {
                if (err) return showError(err);

                initiateProcessing(files, (err3) => {
                    v.processing = false;
                    if (err3) return showError(err3);
                    loadErrorFiles();
                    showSuccess('Processing has been completed');
                });
            });
        },
        initiateProcessing(files, callback) {
            const { $store, $refs } = this;
            const { starsAdmin, isDemoSchool } = this;
            const { starsClassroomProcessed, starsAdminProcessed } = this;
            const { dispatch } = $store;

            const ats = this.$_dataSystemMixins_getDataSystemForAts;
            const google = this.$_dataSystemMixins_getDataSystemForGoogle;
            this.processing = true;


            async.auto({
                starsAdmin(next) {
                    // dont process if all are not new uploads
                    if (!starsAdmin) return next(null, false);
                    const ready = starsAdmin.files.every((f) => (f.syncFile.isRequired && f.localFile) || !f.syncFile.isRequired);
                    if (!ready && !isDemoSchool) {
                        window.console.log('Skipping starsAdmin post processing, all required files not submitted at once');
                        return dispatch(Types.actions.REFRESH_DATA_SYSTEMS, (err2) => {
                            if (err2) return next(err2);
                            next(null, false);
                        });
                    }
                    window.console.log('Starting post processing via $refs.dataSystemStars');
                    $refs.dataSystemStarsAdmin.startProcessing(files, (err) => {
                        if (err) return next(err);
                        dispatch(Types.actions.REFRESH_DATA_SYSTEMS, (err2) => {
                            if (err2) return next(err2);
                            next(null, true);
                        });
                    });
                },
                ats: ['starsAdmin', function (results, next) {
                    const atsReady = (starsAdminProcessed || starsClassroomProcessed) && ats.files.some((f) => f.localFile);
                    if (!atsReady) {
                        window.console.log('Skipping dataSystemAts processing');
                        return next(null, false);
                    }

                    window.console.log('Starting post processing via $refs.dataSystemAts');
                    const system = $refs.dataSystemAts;
                    // @ts-ignore
                    system.startProcessing(files, next);
                }],
                google: ['ats', function (results, next) {
                    const googleReady = (starsAdminProcessed || starsClassroomProcessed) && google.files.some((f) => f.localFile);
                    if (!googleReady) {
                        googleReady.console.log('Skipping dataSystemGoogle post processing, all required files not submitted at once');
                        return next(null, false);
                    }
                    window.console.log('Starting post processing via $refs.dataSystemGoogle');
                    const system = $refs.dataSystemGoogle;
                    // @ts-ignore
                    system.startProcessing(next);
                }],
            }, 1, (err) => {
                if (err) return callback(err);
                dispatch(Types.actions.REFRESH_DATA_SYSTEMS, (err2) => {
                    if (err2) return callback(err2);
                    window.console.log('All post processing has been completed');
                    callback();
                });
            });
        },
        initiateClassroomProcessing() {
            const { starsClassroom } = this;
            if (!starsClassroom) return;

            const ready = starsClassroom.files.every((f) => f.localFile);
            if (!ready) {
                window.console.log('Skipping starsClassroom post processing, all required files not submitted at once');
                return;
            }

            window.console.log('Starting post processing via $refs.dataSystemStarsClassroom');
            this.STARSClassroomProcessing = true;
            const v = this;

            const { schoolTermEncryptionKey } = v.$store.state.database;
            const files = starsClassroom.files.map((f) => ({ file: f.localFile, syncFile: f.syncFile }))
                .sort((a, b) => a.syncFile.importRank - b.syncFile.importRank);
            STARSClassroomUtil.process(files, v.user, schoolTermEncryptionKey || null, (err) => {
                v.loading = false;
                this.STARSClassroomProcessing = false;
                if (err) return v.showError(err);
                window.location.reload();
            });
        },
        showSuccess(message) {
            this.successMessage = message;
            setTimeout(() => {
                this.successMessage = null;
            }, 30000);
        },
        onFileBrowseSelect(e) {
            const files = Array.from(e.target.files);
            this.loadFiles(files);
        },
        triggerFileBrowser() {
            document.getElementById('file-input').click();
        },
        showDataSystem(name) {
            return Boolean(this.dataSystems.find((c) => c.dataSystemName == name));
        },
        // drag drop stuff
        onDragOver(e) {
            e.stopPropagation();
            e.preventDefault();

            const dt = e.dataTransfer;
            this.showDropZone = (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files')));
        },
        onDragLeave(e) {
            e.stopPropagation();
            e.preventDefault();
            this.hideDropZone();
        },
        onDrop(e) {
            e.stopPropagation();
            e.preventDefault();
            const files = Array.from(e.dataTransfer.files);

            this.loadFiles(files);
        },
        hideDropZone() {
            const v = this;
            if (v.dragTimer) clearTimeout(v.dragTimer);
            v.dragTimer = setTimeout(() => {
                v.showDropZone = false;
            }, 25);
        },

    },
});
</script>

<style scoped>

a.kt-header__brand-logo {
  height: 100vh;
  display: block;
  color: white;
}
.kt-header__brand-logo svg {
    opacity: 0.4;
    height: 50vh;
    max-width: 50vw;
}
.kt-header__brand-logo h1 {
    color: #0f88ef;
    font-size: 2.8rem;
    opacity: 0.4;
}

</style>
