import async from 'async';
import XLSX from '@sheet/crypto/dist/xlsx.full.min';

const allowedFileTypes = [{
    name: 'highSchoolReportCardExcel',
    extension: '.xls',
}];

export default function process(file, user, callback) {
    async.tryEach([
        function excel(next) {
            checkForHSEgg(file, user, next);
        },
    ], (err, result) => {
        if (err) return callback(err);
        if (!result) return callback('Error reading file');
        callback(err, result);
    });
}

function checkForHSEgg(file, user, callback) {
    const reader = new FileReader();
    reader.onload = function (event) {
        // @ts-ignore
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array', cellStyles: true });

        if (workbook.SheetNames.length !== 4) return callback('Unknown XLS file');
        if (workbook.SheetNames[1] !== 'Grade_Data') return callback('Unknown XLS sheet');

        // const gradeData = XLSX.utils.sheet_to_json(workbook.Sheets.Grade_Data);

        const worksheet = workbook.Sheets.Main;
        const schoolCell = worksheet.F11;
        const school = (schoolCell ? schoolCell.v : undefined);
        if (school != user.school.externalSchoolId) {
            return callback('Invalid school for HS EGG File');
        }
        const yearCell = worksheet.F12;
        const year = (yearCell ? yearCell.v : undefined);
        if (year != user.school.schoolYear) {
            return callback('Invalid school year for HS EGG File');
        }
        const termCell = worksheet.F13;
        const term = (termCell ? termCell.v : undefined);
        if (term != user.school.schoolTerm) {
            return callback('Invalid school term for HS EGG File');
        }
        const mpCell = worksheet.F14;
        const mp = (mpCell ? mpCell.v : undefined);

        const fileType = allowedFileTypes.find((f) => f.name == 'highSchoolReportCardExcel');
        return callback(null, `${fileType.name}_mp${mp}`);
    };
    reader.onerror = function () {
        return callback('Error reading file');
    };
    reader.readAsArrayBuffer(file);
}
