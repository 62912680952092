import async from 'async';
import moment from 'moment';
import numeral from 'numeral';
import XLSX from '@sheet/crypto/dist/xlsx.full.min';
import * as util from './util';
import * as network from '../../network';

/**
 * @param {Array} files
 * @param {Object} user
 * @param {Function} callback
 */
function process(files, user, schoolTermEncryptionKey, callback) {
    async.eachOfSeries(files, (f, i, next) => {
        const { file, syncFile } = f;
        file.fileId = `file_${new Date().getTime()}`;
        processFile(file, syncFile, user, schoolTermEncryptionKey, (err) => {
            if (err) {
                util.updateVue(null, null, syncFile);
                return next(err);
            }
            if (file.error) return next(file.error);
            return next(err);
        });
    }, (err) => {
        if (err) return callback(err);
        callback(err);
    });
}

function processFile(file, syncFile, user, schoolTermEncryptionKey, callback) {
    const fileName = file.name.toLowerCase();
    if (!fileName.endsWith('.xlsx')) return callback(new Error('Bad extension'));
    if (!syncFile) return callback(new Error('No sync file type found'));
    const { internalName } = syncFile;

    const {
        schoolId, schoolTermId, externalSchoolId, schoolYear, schoolTerm,
    } = user.school;

    async.auto({
        data(next) {
            const reader = new FileReader();
            reader.onload = function (event) {
                // @ts-ignore
                const data = new Uint8Array(event.target.result);
                next(null, data);
            };
            reader.onerror = function () {
                return next(new Error('Error reading file'));
            };
            reader.readAsArrayBuffer(file);
        },
        schoolStaff(next) {
            network.teachers.listTeachers({ url: { schoolId, schoolTermId } }, (err, res) => {
                if (err) return next(err);
                next(null, res.teachers);
            });
        },
        students(next) {
            network.students.listStudents({ url: { schoolId, schoolTermId } }, (err, res) => {
                if (err) return next(err);
                next(null, res.students);
            });
        },
        workbook: ['data', function (results, next) {
            const { data } = results;
            let workbook;
            try {
                workbook = XLSX.read(data, { type: 'array', cellStyles: true });
            } catch (e) {
                return next(e);
            }
            return next(null, workbook);
        }],
        validate: ['workbook', function (results, next) {
            const { workbook } = results;
            if (internalName === 'classroom_schedules') {
                if (workbook.SheetNames.length !== 1) return next(new Error('Unknown XLS file'));
                if (!['Page1_1', 'Page1'].includes(workbook.SheetNames[0])) return next(new Error('Unknown XLS sheet'));
                const worksheet = workbook.Sheets.Page1_1 || workbook.Sheets.Page1;

                const schoolCell = worksheet.A6;
                const school = (schoolCell ? schoolCell.v : '');
                if (!school.endsWith(externalSchoolId)) return next(new Error('Invalid school for upload'));

                const schoolYearCell = worksheet.A7;
                const schoolYearMatch = (schoolYearCell ? schoolYearCell.v : '');
                if (!schoolYearMatch.endsWith(schoolYear)) return next(new Error('Invalid schoolYear for upload'));

                const schoolTermCell = worksheet.A8;
                const schoolTermMatch = (schoolTermCell ? schoolTermCell.v : '');
                if (!schoolTermMatch.endsWith(schoolTerm)) return next(new Error('Invalid schoolTerm for upload'));

                return next(null, { internalName: 'classroom_schedules' });
            }
            if (internalName === 'classroom_rosters') {
                if (workbook.SheetNames.length !== 2) return next(new Error('Unknown XLS file'));
                if (!['Teacher_Staff Details_1', 'Teacher_Staff Details'].includes(workbook.SheetNames[0])) return next(new Error('Unknown XLS sheet'));
                if (!['Student Details_2', 'Student Details'].includes(workbook.SheetNames[1])) return next(new Error('Unknown XLS sheet'));
                const worksheet = workbook.Sheets['Teacher_Staff Details_1'] || workbook.Sheets['Teacher_Staff Details'];

                let match = false;
                let matchingCell = null;
                ['A5', 'A7'].forEach((cellLocation) => {
                    const schoolCell = worksheet[cellLocation];
                    const school = (schoolCell ? schoolCell.v : '');
                    if (school.endsWith(externalSchoolId)) {
                        match = true;
                        matchingCell = cellLocation;
                    }
                });
                if (!match) return next(new Error('Invalid school for upload'));

                return next(null, { internalName: 'classroom_rosters', matchingCell });
            }
            return next(new Error('Unknown STARS Classroom sync file type'));
        }],
        teacherSubjectClass: ['schoolStaff', 'students', 'validate', function (results, next) {
            const {
                schoolStaff, students, workbook, validate,
            } = results;
            if (!validate || validate.internalName !== 'classroom_schedules') return next();
            const options = {
                range: 'A6:I10000',
                header: [
                    'courseName', 'courseCode', 'courseSection', 'studentName',
                    'extStudentId', 'emailAddress', 'startDate', 'endDate', 'status'],
            };
            window.syncGrades.log('Submitting Teacher Subject Class Data', 'info');

            let schoolStaffId = 0;
            let teacher = null;
            const rows = XLSX.utils.sheet_to_json(workbook.Sheets.Page1_1 || workbook.Sheets.Page1, options).map((row) => {
                if (!row.courseName) return null;
                const isHeaderRow = Boolean(!row.hasOwnProperty('courseSection'));
                if (isHeaderRow) {
                    if (row.courseName.startsWith('Teacher Name:')) {
                        schoolStaffId = 0;
                        const teacherMatch = schoolStaff.find((staff) => row.courseName.trim().toLowerCase().endsWith(`${staff.lastName}, ${staff.firstName}`.toLowerCase())) || null;
                        if (teacherMatch) {
                            schoolStaffId = teacherMatch.schoolStaffId;
                            teacher = `${teacherMatch.lastName}, ${teacherMatch.firstName}`;
                        }
                    }

                    return null;
                }

                if (schoolStaffId > 0 && row.courseName !== 'Course Name' && row.status === 'Active in class') {
                    const data = { ...row };
                    data.schoolStaffId = schoolStaffId;
                    data.teacher = teacher;

                    const studentMatch = students.find((student) => row.extStudentId == student.extStudentId) || null;
                    if (studentMatch) {
                        data.studentEnrollmentId = studentMatch.studentEnrollmentId;
                        return {
                            ...data,
                            extStudentId: data.extStudentId.toString(),
                            startDate: data.startDate.toString(),
                            endDate: data.endDate.toString(),
                        };
                    }
                }

                return null;
            }).filter((row) => row && row.schoolStaffId && row.studentEnrollmentId);

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    internalName: validate.internalName,
                },
                body: {
                    schoolTermEncryptionKey,
                    dataSource: { rows },
                },
            };

            network.storage.uploadDataSource(params, next);
        }],
        studentStaffDetails: ['validate', function (results, next) {
            const { workbook, validate } = results;
            if (!validate || validate.internalName !== 'classroom_rosters') return next();
            window.syncGrades.log('Submitting Student Staff Details Data', 'info');

            const { matchingCell } = validate;
            if (!matchingCell) return next(new Error('Invalid matching cell'));
            const teachers = XLSX.utils.sheet_to_json(workbook.Sheets['Teacher_Staff Details_1'] || workbook.Sheets['Teacher_Staff Details'], { range: matchingCell === 'A5' ? 'A7:E9000' : 'A9:E9000' })
                .map((row) => ({
                    firstName: row.First_Name,
                    lastName: row.Last_Name,
                    jobTitle: row.Job_Title,
                    // employeeId: row.Employee_ID,
                    emailAddress: (row.Email_Address || '').toString().replace(/\s+/g, ' ').trim().toLowerCase(),
                })).filter((row) => row.emailAddress && row.lastName);

            const students = XLSX.utils.sheet_to_json(workbook.Sheets['Student Details_2'] || workbook.Sheets['Student Details'], { range: matchingCell === 'A5' ? 'A7:E9000' : 'A9:E9000' }).map((row) => ({
                firstName: row.First_Name,
                lastName: row.Last_Name,
                extStudentId: row.Student_ID.toString(),
                emailAddress: (row.Email_Address || '').toString().replace(/\s+/g, ' ').trim().toLowerCase(),
            })).filter((row) => row.extStudentId && row.emailAddress && row.emailAddress !== 'null');

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    internalName: validate.internalName,
                },
                body: {
                    schoolTermEncryptionKey,
                    dataSource: { teachers, students },
                },
            };

            network.storage.uploadDataSource(params, next);
        }],
        uploadFile: ['teacherSubjectClass', 'studentStaffDetails', function (results, next) {
            const params = { localFile: file, syncFile };
            uploadFile(params, user, next);
        }],
    }, (err, results) => {
        if (err) {
            const f = file;
            f.err = `${err}`;
            return uploadError(f, user, (err2, errFile) => {
                callback(err2 || errFile.fileError);
            });
        }
        callback(err, results);
    });
}

/** UPLOAD TO AWS + SYNCFILE UPLOAD
 * @param {Object} file
 * @param {Object} user
 * @param {Function} callback
 */
function uploadFile(file, user, callback) {
    const { localFile, syncFile } = file;
    const { internalName } = syncFile;
    const { schoolId, schoolTermId } = user.school;

    async.auto({
        parameters(next) {
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    fileType: 'data-uploads',
                },
                body: {
                    fileParameters: {
                        fileName: localFile.name,
                    },
                },
            };

            network.storage.getAWSFormDetails(params, (err, formDetails) => {
                if (err) return next(err);
                next(null, formDetails);
            });
        },
        aws: ['parameters', function (results, next) {
            awsUpload(results.parameters, localFile, syncFile, next);
        }],
        syncFileUploads: ['aws', function (results, next) {
            const bucketPath = results.parameters.fields.key;
            const fileSize = numeral(localFile.size).format('0.0 b');
            const fileLastModified = moment(new Date(localFile.lastModified)).format('llll');

            const params = {
                url: { schoolId, schoolTermId, internalName },
                body: {
                    bucketPath,
                    fileSize,
                    fileLastModified,
                },
            };
            network.storage.saveSyncFileDetails(params, next);
        }],
    }, (err, results) => {
        if (err) return callback(err);
        callback(err, results);
    });
}

/**
 * @param {Object} localFile
 * @param {Object} user
 * @param {Function} callback
 */
function uploadError(localFile, user, callback) {
    const { schoolId, schoolTermId } = user.school;
    async.auto({
        parameters(next) {
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    fileType: 'error-bin',
                },
                body: {
                    fileParameters: {
                        fileName: localFile.name,
                    },
                },
            };
            network.storage.getAWSFormDetails(params, (err, res) => {
                if (err) return next(err);
                next(null, res);
            });
        },
        aws: ['parameters', function (results, next) {
            awsUpload(results.parameters, localFile, null, next);
        }],
        saveError: ['aws', function (results, next) {
            const bucketPath = results.parameters.fields.key;
            const fileSize = numeral(localFile.size).format('0.0 b');
            const fileName = localFile.name;
            const fileLastModified = moment(new Date(localFile.lastModified)).format('llll');

            const params = {
                url: { schoolId, schoolTermId },
                body: {
                    fileName,
                    bucketPath,
                    fileSize,
                    fileLastModified,
                    fileError: localFile.err ? localFile.err : 'Unknown file',
                },
            };
            network.storage.saveSyncFileError(params, (err, res) => {
                if (err) return next(err);
                next(err, res.file);
            });
        }],
    }, (err, results) => {
        if (err) return callback(err);
        callback(err, results.saveError);
    });
}

/** ONLY UPLOAD TO AWS BUCKET AND RETURN BUCKET PATH
 * @param {Object} file
 * @param {Object} user
 * @param {Function} callback
 */
function uploadToAWS(fileType, file, user, callback) {
    const { schoolId, schoolTermId } = user.school;
    async.auto({
        parameters(next) {
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    fileType,
                },
                body: {
                    fileParameters: {
                        fileName: file.name,
                    },
                },
            };

            network.storage.getAWSFormDetails(params, (err, formDetails) => {
                if (err) return next(err);
                next(null, formDetails);
            });
        },
        aws: ['parameters', function (results, next) {
            awsUpload(results.parameters, file, false, next);
        }],
    }, (err, results) => {
        if (err) return callback(err);
        callback(err, results);
    });
}

/**
 * @param {Object} awsParameters
 * @param {Object} localFile
 * @param {Object} syncFile
 * @param {Function} callback
 */
function awsUpload(awsParameters, localFile, syncFile, callback) {
    const formData = new FormData();
    Object.keys(awsParameters.fields).forEach((name) => {
        formData.append(name, awsParameters.fields[name]);
    });

    const fileProgress = {
        status: 'Preparing',
        percentComplete: 0,
        stringPercentage: '0%',
        error: null,
    };

    formData.append('file', localFile);
    // presentation.bucketPath = awsParameters.fields.key;

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
            fileProgress.status = 'Uploading';
            fileProgress.percentComplete = Math.round((evt.loaded * 100) / evt.total);
            fileProgress.stringPercentage = (`${fileProgress.percentComplete.toString()}%`);
        }
    }, false);

    xhr.addEventListener('load', (evt) => {
        // @ts-ignore
        if (evt.target.status >= 200 && evt.target.status < 300) {
            fileProgress.error = null;
            fileProgress.status = 'Uploaded';
            fileProgress.percentComplete = 100;
            fileProgress.stringPercentage = '100%';
        } else {
            fileProgress.status = 'Error';
            fileProgress.error = 'Upload failed';
            fileProgress.stringPercentage = '0%';
        }
        return callback();
    }, false);

    xhr.addEventListener('error', () => {
        fileProgress.status = 'Error';
        fileProgress.error = 'Upload failed';
        fileProgress.stringPercentage = '0%';
        return callback();
    }, false);

    xhr.addEventListener('abort', () => {
        fileProgress.status = 'Error';
        fileProgress.error = 'Upload failed';
        fileProgress.stringPercentage = '0%';
        return callback();
    }, false);

    const { bucket } = awsParameters.fields;
    xhr.open('POST', `https://s3.amazonaws.com/${bucket}`, true);
    xhr.send(formData);
}

export {
    process,
    uploadFile,
    uploadError,
    uploadToAWS,
    awsUpload,
};
