var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.serverSideEncryptionEnabled && _vm.clientSideEncryptionEnabled
    ? _c("div", [
        _vm._m(0),
        _c("div", { staticClass: "row mr-0 ml-0 w-100 text-center" }, [
          _c(
            "button",
            {
              staticClass:
                "btn btn-label-primary btn-lg kt-font-lg btn-upper mt-3",
              staticStyle: { "min-width": "260px", margin: "auto" },
              attrs: { type: "button" },
              on: { click: _vm.startDecryption },
            },
            [_vm._v(" Unlock ")]
          ),
        ]),
      ])
    : _c(
        "div",
        {
          staticClass: "kt-container kt-grid__item kt-grid__item--fluid",
          on: {
            dragleave: _vm.onDragLeave,
            dragover: _vm.onDragOver,
            drop: _vm.onDrop,
          },
        },
        [
          _vm.showDropZone
            ? _c("div", { staticClass: "w-100" }, [
                _c(
                  "a",
                  { staticClass: "kt-header__brand-logo w-100 text-center" },
                  [
                    _c("SVGIcon", {
                      staticClass: "logo loading",
                      attrs: { name: "dropZone" },
                    }),
                    _c("h1", { staticClass: "kt-font-bolder" }, [
                      _vm._v(" Drop files here to upload! "),
                    ]),
                  ],
                  1
                ),
              ])
            : [
                _vm.isSetupWizard && _vm.step
                  ? _c("SetupWizardStepStatus", {
                      attrs: {
                        status: _vm.step.status,
                        message: _vm.step.message,
                      },
                    })
                  : _vm._e(),
                _vm._m(1),
                !_vm.processing
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          class: [
                            _vm.errorFiles.length == 0
                              ? "col-12"
                              : "col-xl-6 col-lg-6 col-md-6",
                          ],
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-portlet kt-portlet--height-fluid",
                            },
                            [
                              _vm._m(2),
                              _c("div", { staticClass: "kt-portlet__body" }, [
                                _c("input", {
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    id: "file-input",
                                    type: "file",
                                    name: "file",
                                    accept: ".csv",
                                    multiple: "",
                                  },
                                  on: { change: _vm.onFileBrowseSelect },
                                }),
                                _c("div", { staticClass: "form-group pt-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.triggerFileBrowser.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder: "Choose Files",
                                          readonly: "readonly",
                                        },
                                      }),
                                      _vm._m(3),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm.errorFiles.length !== 0
                        ? _c(
                            "div",
                            { staticClass: "col-xl-6 col-lg-6 col-md-6" },
                            [
                              _c("ErrorBin", {
                                attrs: { "error-files": _vm.errorFiles },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.successMessage && !_vm.processing
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "kt-demo__preview" }, [
                          _c(
                            "div",
                            {
                              staticClass: "alert alert-success",
                              attrs: { role: "alert" },
                            },
                            [
                              _c("div", { staticClass: "alert-text" }, [
                                _c("h4", { staticClass: "alert-heading" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.successMessage) + "! "
                                  ),
                                ]),
                                _c("p", { staticClass: "mt-3" }, [
                                  _vm._v(
                                    " You have successfully imported new roster data into SyncGrades. Reload this page to be sure you are seeing the most recent rosters "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.district === "ICAHN"
                  ? _c("div", { staticClass: "row" }, [_vm._m(4)])
                  : _vm._e(),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      class: [
                        (_vm.starsAdmin && _vm.starsClassroom) ||
                        !_vm.rostersReady
                          ? "col-12"
                          : "col-xl-6 col-lg-6 col-md-6",
                      ],
                    },
                    [
                      _vm.starsAdmin
                        ? _c("DataSystemStarsAdmin", {
                            ref: "dataSystemStarsAdmin",
                            attrs: { name: "STARS Admin", extension: "csv" },
                          })
                        : _vm._e(),
                      _vm.starsClassroom
                        ? _c("DataSystemStarsClassroom", {
                            ref: "dataSystemStarsClassroom",
                            attrs: {
                              name: "STARS Classroom",
                              extension: "xlsx",
                              processing: _vm.STARSClassroomProcessing,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.rostersReady
                    ? _c(
                        "div",
                        {
                          class: [
                            !_vm.starsClassroom && !_vm.starsAdmin
                              ? "col-12"
                              : "col-xl-6 col-lg-6 col-md-6",
                          ],
                        },
                        [
                          _vm.ats
                            ? _c("DataSystemAts", {
                                ref: "dataSystemAts",
                                attrs: { name: "ATS" },
                              })
                            : _vm._e(),
                          !_vm.$_userMixins_isGoogleDisabled
                            ? _c("DataSystemGoogle", {
                                ref: "dataSystemGoogle",
                                attrs: { name: "Google Linking" },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
        ],
        2
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row mr-0 ml-0 w-100" }, [
      _c(
        "div",
        {
          staticClass: "alert alert-light alert-elevate w-100 fade show",
          attrs: { role: "alert" },
        },
        [
          _c("div", { staticClass: "alert-icon" }, [
            _c("i", { staticClass: "la la-info-circle kt-font-danger" }),
          ]),
          _c("div", { staticClass: "alert-text" }, [
            _vm._v(
              " Encryption is enabled for this school term. In order to upload updated rosters, you must first unlock the encryption. "
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          {
            staticClass: "alert alert-light alert-elevate fade show",
            attrs: { role: "alert" },
          },
          [
            _c("div", { staticClass: "alert-icon" }, [
              _c("i", { staticClass: "flaticon-warning kt-font-brand" }),
            ]),
            _c("div", { staticClass: "alert-text" }, [
              _vm._v(
                " Place all of the STARS and ATS downloads in one folder, then drag and drop or select them all to process uploads. "
              ),
            ]),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" File Uploader "),
        ]),
      ]),
      _c("div", { staticClass: "kt-portlet__head-toolbar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v(" Browse ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col" }, [
      _c(
        "div",
        {
          staticClass: "alert alert-light alert-elevate fade show",
          attrs: { role: "alert" },
        },
        [
          _c("div", { staticClass: "alert-icon" }, [
            _c("i", { staticClass: "la la-info-circle kt-font-danger" }),
          ]),
          _c("div", { staticClass: "alert-text pt-2 pb-2" }, [
            _vm._v(
              " An updated ROCL is always required to be uploaded and processed FIRST before any other files. "
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }