export default function identify(file, user, callback) {
    const reader = new FileReader();

    reader.onload = function (event) {
        const rows = event.target.result.split(/\r\n|\n/);
        let columnNames = [];

        if (rows.length > 2) {
            columnNames = rows[0].split(','); // parse row 1 by default
            if (rows[0].indexOf('EMERGENCY CONTACT LIST') === 0) columnNames = rows[2].split(',');

            // Sesis Recommended Programs
            if (rows[0].indexOf('Textbox8') === 0) columnNames = rows[3].split(',');
            else if (rows[0].indexOf('Program Recommendations') === 0) columnNames = rows[1].split(',');

            // 6.65 validation
            if (rows[0].indexOf('Teacher,FullTeacherName,EmpID,EisID,Mail') > -1) {
                if (rows[1].indexOf(`${user.school.externalSchoolId},${user.school.schoolYear},${user.school.schoolTerm},`) == -1) {
                    return callback('This file was generated for another school or term');
                }
            }

            // ROCL validation
            if (rows[0].indexOf('ATS - New York City') === 0) {
                columnNames = rows[3].split(',');
            }
        } else {
            return callback('No row data in upload');
        }

        const fileTypes = getAllowedFileTypes(user);

        const match = fileTypes.find((fileType) => {
            columnNames.forEach((column) => {
                fileType.fields.forEach((f) => {
                    const field = f;
                    if (field.names) {
                        field.names.forEach((name) => {
                            if (name == column) field.found = true;
                        });
                    } else if (field.name == column) field.found = true;
                });
            });

            const missing = fileType.fields.filter((field) => field.found == false);

            return missing.length == 0;
        });

        if (!match) return callback('Unknown file type');
        return callback(null, match.name);
    };
    reader.onerror = function () {
        return callback('Error reading file');
    };

    reader.readAsText(file);
}

function getAllowedFileTypes() {
    // const schoolYear = parseInt(user.school.name.substring(0, 4), 10);
    const allowedFileTypes = [{
        name: 'students-301',
        extension: 'csv',
        fields: [
            { name: 'StudentID', found: false },
            { name: 'LastName', found: false },
            { name: 'FirstName', found: false },
            { name: 'GradeLevel', found: false },
            { name: 'Student DOE Email', found: false },
            { name: 'ParentLastName', found: false },
            { name: 'ParentFirstName', found: false },
            { name: 'Phone', found: false },
            { name: 'Street', found: false },
            { name: 'City', found: false },
            { name: 'State', found: false },
            { name: 'Zip', found: false },
        ],
    }, {
        name: 'schedules-665',
        extension: 'csv',
        fields: [
            { name: 'School', found: false },
            { name: 'Year', found: false },
            { name: 'Student', found: false },
            { name: 'LastName', found: false },
            { name: 'FirstName', found: false },
            { name: 'GradeLevel', found: false },
            { name: 'OffClass', found: false },
            { name: 'Course', found: false },
            { name: 'Section', found: false },
            { name: 'CourseName', found: false },
            { name: 'Teacher', found: false },
            { name: 'Mail', found: false },
        ],
    }, {
        name: 'guardians-RCON',
        extension: 'csv',
        fields: [
            { name: 'STUDENT', found: false },
            { name: 'OFF', found: false },
            { name: 'GR', found: false },
            { name: 'ADULT', found: false },
            { name: 'RESIDE', found: false },
            { name: 'REL', found: false },
            { name: 'AUTH', found: false },
            { name: 'WRITTEN', found: false },
            { name: 'ORAL', found: false },
            { name: 'HOME', found: false },
            { name: 'WORK', found: false },
            { name: 'CELL', found: false },
            { name: 'BEEPER', found: false },
        ],
    }, {
        name: 'studentBio-RESI',
        extension: 'csv',
        fields: [
            { name: 'ID', found: false },
            { name: 'DOB', found: false },
            { name: 'GL', found: false },
            { name: 'LPRF', found: false },
            { name: 'LPRF SOURCE', found: false },
            { name: 'LPRF DATE', found: false },
            { name: 'ADM DTE', found: false },
            { name: 'LANG', found: false },
            { name: 'ETH', found: false },
            { name: 'HISP', found: false },
            { name: 'RACE', found: false },
            { name: 'SEX', found: false },
            { name: 'COH YR', found: false },
            { name: 'LEP', found: false },
            { name: 'TOUT YR', found: false },
            { name: 'REL SVC FLG', found: false },
            { name: 'CNSEL', found: false },
            { name: 'SP IMP', found: false },
            { name: 'SETSS', found: false },
            { name: 'CAP PGM', found: false },
            { name: 'CAP DIS', found: false },
            { name: 'CAP CONF DT', found: false },
            { name: 'CAP 1ST ATT DT', found: false },
        ],
    }, {
        name: 'students-ROCL',
        extension: 'csv',
        fields: [
            { name: 'OCL', found: false },
            { name: 'Room', found: false },
            { name: 'LOC', found: false },
            { name: 'Teacher', found: false },
            { name: 'Name', found: false },
            { name: 'Student ID', found: false },
            { name: 'Sex', found: false },
            { name: 'DOB', found: false },
            { name: 'Status', found: false },
            { name: 'GL', found: false },
            { name: 'Grade', found: false },
            { name: 'Phone #', found: false },
        ],
    }, {
        name: 'studentLink-Google',
        extension: 'csv',
        fields: [
            { name: 'StudentID', found: false },
            { name: 'Google Email Address', found: false },
        ],
    }, {
        name: 'teacherLink-Google',
        extension: 'csv',
        fields: [
            { name: 'NYC Email Address', found: false },
            { name: 'Google Email Address', found: false },
        ],
    }, {
        name: 'teachers-642',
        extension: 'csv',
        fields: [
            { name: 'Teacher', found: false },
            { name: 'LastName', found: false },
            { name: 'FirstName', found: false },
            { name: 'Mail', found: false },
        ],
    }, {
        name: 'coteachers-423',
        extension: 'csv',
        fields: [
            { name: 'Course', found: false },
            { name: 'Section', found: false },
            { name: 'CourseName', found: false },
            { name: 'Teacher', found: false },
            { name: 'Co-Teacher', found: false },
        ],
    }, {
        name: 'courses-415',
        extension: 'csv',
        fields: [
            { name: 'Year', found: false },
            { name: 'Term', found: false },
            { name: 'Course', found: false },
            { name: 'Section', found: false },
            { name: 'CycleDay', found: false },
            { name: 'Period', found: false },
            { name: 'Teacher', found: false },
            { name: 'Room', found: false },
        ],
    }, {
        name: 'sesis-programs',
        extension: 'csv',
        fields: [
            { names: ['Student_ID', 'Student ID'], found: false },
            { names: ['Date_of_IEP', 'Date of IEP Meeting'], found: false },
            { names: ['Annual_Review_Date', 'Annual Review Date'], found: false },
            { names: ['Mandated_3_Year_Reevaluation_Date', 'Mandated 3-Year Reevaluation Date'], found: false },
            { name: 'Accessibility', found: false },
            { names: ['Participates_in_Alternate_Assessment', 'Participates in Alternate Assessment'], found: false },
            { name: 'Programs', found: false },
            { names: ['Related_Services', 'Related Services'], found: false },
            { names: ['Compensatory_Services', 'Compensatory Services'], found: false },
        ],
    }, {
        name: 'transcripts',
        extension: 'csv',
        fields: [
            { name: 'StudentID', found: false },
            { name: 'School', found: false },
            { name: 'Year', found: false },
            { name: 'Term', found: false },
            { name: 'Course', found: false },
            { name: 'Course Title', found: false },
            { name: 'Instructional Level', found: false },
            { name: 'Mark', found: false },
            { name: 'Credits', found: false },
        ],
    }, {
        name: 'exam-history',
        extension: 'csv',
        fields: [
            { name: 'StudentID', found: false },
            { name: 'School', found: false },
            { name: 'Year', found: false },
            { name: 'Term', found: false },
            { name: 'GEC', found: false },
            { name: 'Course', found: false },
            { name: 'Title', found: false },
            { name: 'Mark', found: false },
        ],
    }];

    const types = allowedFileTypes.map((f) => {
        const fileType = f;
        fileType.fields = fileType.fields.map((x) => {
            const field = x;
            field.found = false;
            return field;
        });
        return fileType;
    });
    return types;
}
