import async from 'async';
import * as xls from './extensions/xls';
import * as xlsx from './extensions/xlsx';
import * as csv from './extensions/csv';
import * as pdf from './extensions/pdf';
import * as util from './util';

/**
 * @param {Array} files
 * @param {Array} dataSystems
 * @param {Object} user
 * @param {Function} callback
 */
export default function identify(files, dataSystems, user, callback) {
    async.map(files, (f, next) => {
        const file = f;
        file.fileId = `file_${new Date().getTime()}`;
        identifyFile(file, dataSystems, user, (err, result) => {
            if (err) return next(err);
            if (file.error) return next(file.error);
            if (result.syncFile && ['classroom_schedules', 'classroom_rosters'].includes(result.syncFile.internalName)) util.updateVue(null, file, result.syncFile);
            return next(err, {
                syncFile: result.syncFile,
                uploadedFile: result.uploadedFile,
                localFile: file,
            });
        });
    }, (err, newFiles) => {
        if (err) return callback(err);
        // removes error bin files
        callback(err, newFiles.filter((f) => {
            if (f.syncFile?.syncFileErrorId > 0) {
                return false;
            } return true;
        }));
    });
}

function identifyFile(file, dataSystems, user, callback) {
    const fileName = file.name.toLowerCase();
    let internalName = null;

    async.auto({
        xls(next) {
            if (fileName.endsWith('.xls')) {
                xls.default(file, user, (err, name) => {
                    if (err) return next(err);
                    if (!name) return next('Unknown xls file');
                    internalName = name;
                    next();
                });
                return;
            }
            return next();
        },
        csv(next) {
            if (fileName.endsWith('.csv')) {
                 csv.default(file, user, (err, name) => {
                    if (err) return next(err);
                    if (!name) return next('Unknown csv file');
                    internalName = name;
                    next();
                });
                return;
            }
            return next();
        },
        xlsx(next) {
            if (fileName.endsWith('.xlsx')) {
                xlsx.default(file, user, (err, name) => {
                    if (err) return next(err);
                    if (!name) return next('Unknown xlsx file');
                    internalName = name;
                    next();
                });
                return;
            }
            return next();
        },
        pdf(next) {
            if (fileName.endsWith('.pdf')) {
                pdf.default(file, user, (err, name) => {
                    if (err) return next(err);
                    if (name) return next('Unknown pdf file');
                    internalName = name;
                    next();
                });
                return;
            }
            return next();
        },
    }, (err) => {
        if (err) {
            const f = file;
            f.err = `${err}`;
            return util.uploadError(f, user, (err2, errFile) => {
                callback(err2 || errFile.fileError);
            });
        }
        if (!internalName) {
            const f = file;
            f.err = 'Unknown file';
            return util.uploadError(f, user, (err2, errFile) => {
                callback(err2 || errFile.fileError);
            });
        }
        lookupFile(internalName, file, dataSystems, callback);
    });
}

function lookupFile(internalName, file, dataSystems, callback) {
    let syncFile = null;
    dataSystems.forEach((dataSystem) => {
        const found = dataSystem.files.find((f) => f.syncFile.internalName == internalName) || null;
        if (found) syncFile = found;
    });
    if (!syncFile) return callback(new Error('File type not found'));
    return callback(null, syncFile);
}
